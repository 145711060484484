body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-gray-200 {
  background-color: #e5e7eb;
}
.bg-blue-600 {
  background-color: #2563eb;
}
.bg-green-600 {
  background-color: #16a34a;
}
.bg-yellow-600 {
  background-color: #ca8a04;
}
.rounded-full {
  border-radius: 9999px;
}
/*.h-2.5 {
  height: 0.625rem;
}*/